import React from "react"
import { graphql } from "gatsby"
import Link from 'gatsby-link'
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/intro.css"

const IntroPage = ({ data }) => {
  return (
    <>
    <SEO title="Intro" />
    <Img fluid={data.directusIntro.image.localFile.childImageSharp.fluid} className="intro--background-image"/>
    <div className="grid--margin intro" key={data.directusIntro.id}>
      <h1>{data.directusIntro.heading}</h1>
      <p className="p1">{data.directusIntro.text}</p>
    </div>
    <Link to="/" className="intro--enter-page"><span className="cont"><span className="circle"></span><span className="dot"></span></span>Enter site</Link>
  </>
)}
export default IntroPage
export const query = graphql`
  query {
    directusIntro {
      id
      heading
      text
      image {
        localFile {
          childImageSharp {
            fluid(quality: 75) {
                ...GatsbyImageSharpFluid_withWebp              }
            }
        }
      }
    }
  }  
`
